<template>
  <div>
    <b-card>
      <b-row class="align-items-end">
        <b-col md="3">
          <label
            for="dia"
          >Dia</label>
          <v-select
            id="dia"
            v-model="filters.day"
            label="text"
            :options="$store.state.courses.diasDaSemana"
            :reduce="c => c.value"
            @input="filterUpdate"
          >
            <template #no-options="{}">
              A lista de opções está vazia
            </template>
          </v-select>
        </b-col>
        <b-col md="3">
          <label
            for="mod"
          >Modalidade</label>
          <v-select
            id="mod"
            v-model="filters.modalities"
            label="name"
            :options="$store.state.quales.modalities"
            :reduce="c => c.id"
            @input="filterUpdate"
          >
            <template #no-options="{}">
              A lista de opções está vazia
            </template>
          </v-select>
        </b-col>
        <b-col
          md="6"
          class="d-none d-md-block"
        />
        <b-col md="6">
          <label
            for="client"
          >Cliente</label>
          <v-select
            id="client"
            v-model="filters.client"
            label="full_name"
            :options="$store.state.quales.clients"
            :reduce="c => c.id"
            @input="filterUpdate"
          >
            <template #no-options="{}">
              A lista de opções está vazia
            </template>
          </v-select>
        </b-col>
        <b-col
          md="6"
          class="align-bottom mt-1 mt-md-0"
        >
          <b-link
            :to="{ name: 'courses-add' }"
          >
            <b-button
              class="float-right"
              variant="success"
            >
              Nova Aula
            </b-button>
          </b-link>
        </b-col>
      </b-row>
    </b-card>

    <b-overlay
      :show="deleteWarning"
      rounded="sm"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <b-table
          :items="$store.state.courses.clients"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'courses-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                @click="validarDelete(data.item.id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="$store.state.courses.total"
                :per-page="$store.state.courses.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            Você quer deletar a aula: <b>{{ toDelete.modality_name }} do {{ toDelete.professor_name }} no cliente {{ toDelete.local_name }}</b> ?
          </p>
          <b-button
            ref="delete"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="deletar"
          >
            Deletar
          </b-button>
          <b-button
            ref="cancel"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mx-1"
            variant="outline-danger"
            size="sm"
            aria-describedby="cancel-label"
            @click="cancelDelete"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BTable, BDropdown, BDropdownItem, BPagination, BRow, BCol, BButton, BLink, BOverlay, BIcon,
} from 'bootstrap-vue'
import { title } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    // BMedia,
    // BAvatar,
    BLink,
    BIcon,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BFormInput,
    vSelect,
    BOverlay,

  },
  data() {
    return {
      toDelete: {},
      deleteWarning: false,
      search: '',
      city: '',
      filters: {
        day: null,
        client: null,
        modalities: null,
      },
      diaDaSemana: null,
      cliente: null,
      modalidade: null,
      currentPage: 1,
      perPage: 1,
      totalUsers: 100,
      sortBy: null,
      isSortDirDesc: null,
      tableColumns: [
        {
          key: 'modality_name',
          sortable: false,
          label: 'Modalidade',
          formatter: title,
        },
        {
          key: 'professor_name',
          sortable: false,
          label: 'Professor',
        },
        {
          key: 'local_name',
          sortable: false,
          label: 'Cliente',
        },
        {
          key: 'day',
          sortable: false,
          label: 'Dia',
          formatter: value => {
            switch (value) {
              case 0:
                return 'Segunda-Feira'
              case 1:
                return 'Terça-Feira'
              case 2:
                return 'Quarta-Feira'
              case 3:
                return 'Quinta-Feira'
              case 4:
                return 'Sexta-Feira'
              case 5:
                return 'Sábado'
              case 6:
                return 'Domingo'
              default:
                return 'Não definido'
            }
          },
        },
        {
          key: 'time',
          sortable: false,
          label: 'Horário',
        },
        { key: 'actions' },
      ],
    }
  },
  computed: {
    cities() {
      return this.$store.state.courses.citiesOptions ? this.$store.state.courses.citiesOptions : []
    },
  },
  watch: {
    // city(newV) {
    //   this.$store.commit('courses/cityFilter', newV)
    //   this.$store.dispatch('courses/getClients')
    // },
    // search(newV) {
    //   this.$store.commit('courses/searchFilter', newV)
    //   this.$store.dispatch('courses/getClients')
    // },
    currentPage(newV) {
      this.$store.commit('courses/setPage', newV)
      this.$store.dispatch('courses/getClients', this.filters)
    },
  },
  beforeCreate() {
    // this.$store.commit('courses/searchFilter', '')
    // this.$store.commit('courses/cityFilter', '')
    this.$store.dispatch('quales/getClients')
    this.$store.dispatch('quales/getProfessors')
    this.$store.dispatch('quales/getModalities')
  },
  created() {
    this.$store.commit('courses/setPage', 1)
    this.$store.dispatch('courses/getClients', this.filters)
  },
  methods: {
    filterUpdate() {
      this.$store.dispatch('courses/getClients', this.filters)
    },
    cancelDelete() {
      this.toDelete = {}
      this.deleteWarning = false
    },
    validarDelete(userId) {
      [this.toDelete] = this.$store.state.courses.clients.filter(c => c.id === userId)
      this.deleteWarning = true
    },
    deletar() {
      this.$store.dispatch('courses/deleteClient', this.toDelete).then(() => {
        this.toDelete = {}
        this.deleteWarning = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Aula',
            icon: 'UserCheckIcon',
            variant: 'danger',
            text: 'Aula deletada',
          },
        })
        this.$store.dispatch('courses/getClients', this.filters)
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aula',
              icon: 'UserCheckIcon',
              variant: 'warning',
              text: 'Não conseguimos deletar essa aula',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
